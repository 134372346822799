import React from "react"
import ReactHtmlParser from 'react-html-parser';
import "./useCaseSection.scss"

const useCaseSection = ({title, description, image, order}) => {
  return (
        <div className="use-case-section row">
            {order % 2 === 0 && <img src={image} className="col-12 col-md-6" data-sal="fade" data-sal-delay='500' data-sal-easing="ease" data-sal-duration="600"/>}
            <div className="text col-12 col-md-6" data-sal="fade" data-sal-delay='300' data-sal-easing="ease" data-sal-duration="600">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
            {order % 2 !== 0 && <img src={image} className="col-12 col-md-6" data-sal="fade" data-sal-delay='500' data-sal-easing="ease" data-sal-duration="600"/>}
        </div>
    )
}

export default useCaseSection
